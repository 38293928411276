import { useDatoStore } from '@/store/dato.js'

export const useSeo = (seoInput) => {
  const i18nHead = useLocaleHead()
  const setI18nParams = useSetI18nParams()

  const { configuration } = useDatoStore()

  // Se il parametro seo non è reattivo lo rendo reattivo in modo da uniformare il codice
  const seo = isRef(seoInput) ? seoInput : ref(seoInput)
  const meta = generateMeta(seo)

  // Se i parametri localeParams sono settati hreflang e canonical prendono gli slug tradotti
  /*
  {
    it: { slug: 'slug-1'},
    en: { slug: 'slug-2'},
    'en-us': { slug: 'slug-3'}
    ...
  }
  */
  if (seo.value?.localeParams) {
    setI18nParams(seo.value?.localeParams)
  }

  return useHead({
    title:
      seo.value?.title && seo.value?.title !== ''
        ? seo.value?.title + ' | Symmune'
        : configuration.seoFallback?.fallbackSeo?.title,
    meta: [...i18nHead.value.meta, ...meta],
    htmlAttrs: { ...i18nHead.value.htmlAttrs },
    link: [...i18nHead.value.link],
    script: [
      {
        innerHTML: `
          var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="//stats.quammbase.it/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '7']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();
        `,
        type: 'text/javascript'
      }
    ]
  })
}

const generateMeta = (seo) => {
  const runtimeConfig = useRuntimeConfig()
  const route = useRoute()
  const { configuration } = useDatoStore()

  return [
    {
      name: 'description',
      content:
        seo.value?.description && seo.value?.description !== ''
          ? seo.value.description
          : configuration.seoFallback?.fallbackSeo?.description,
    },
    {
      property: 'og:title',
      content:
        seo.value?.title && seo.value?.title !== ''
          ? seo.value?.title + ' | Symmune'
          : configuration.seoFallback?.fallbackSeo?.title,
    },
    {
      property: 'og:description',
      content:
        seo.value?.description && seo.value?.description !== ''
          ? seo.value.description
          : configuration.seoFallback?.fallbackSeo?.description,
    },
    {
      property: 'og:url',
      content: `${runtimeConfig.public.baseURL}${route.fullPath}`,
    },
    {
      property: 'og:image',
      content:
        seo.value?.image && seo.value?.image?.url !== ''
          ? seo.value?.image?.url
          : configuration.seoFallback?.fallbackSeo?.image?.url,
    },
    {
      property: 'og:site_name',
      content:
        configuration.seoFallback?.siteName !== ''
          ? configuration.seoFallback?.siteName
          : `${runtimeConfig.public.siteName}`,
    },
  ]
}
