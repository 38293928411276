import * as dato from './dato.js'
import * as home from './home.js'
import * as blog from './blog.js'
import * as corporates from './corporates.js'
import * as studio from './studio.js'
import * as sitemap from './sitemap.js'
import * as footer from './footer.js'
import * as contents from './contents.js'
import * as solutions from './solutions.js'
import * as industries from './industries.js'
import * as clinical from './clinical.js'
import * as pubblication from './pubblication.js'
import * as news from './news.js'
import * as team from './team.js'
import * as research from './research.js'
import * as lab from './lab.js'

export default {
  dato,
  home,
  blog,
  corporates,
  studio,
  sitemap,
  footer,
  contents,
  solutions,
  industries,
  clinical,
  pubblication,
  news,
  team,
  research,
  lab,
}
