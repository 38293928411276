import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import queries from '@/graphql/dato/queries/index.js'

export const useDatoStore = defineStore('dato', () => {
  //const { locale } = useI18n()

  
  const seoFallback = ref({})

  // Restituisco la configurazione completa
  const configuration = computed(() => ({
    
    seoFallback: seoFallback.value,
  }))

  // Estraggo la configurazione
  const fetchConfiguration = async (locale) => {
    try {
      const { data } = await useAsyncQuery(queries.dato.datoState, {
        locale: locale,
      })

      
      seoFallback.value = data.value?._site?.globalSeo
    } catch (e) {
      console.error(e)
    }
  }

  return {
    configuration,
    fetchConfiguration,
  }
})
