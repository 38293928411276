export const getSitemapData = `
  query GetSitemapData(
  $locale: SiteLocale 
  $first: IntType
  ) {
    allTeams(locale: $locale, first: $first) {
      id
      slug
      
    }
  }
`
